// Import and register all your controllers from the importmap under controllers/*
import { application } from "./application";

import FormController from "./form_controller";
application.register("form", FormController);

import ModalController from "./modal_controller";
application.register("modal", ModalController);

import TableController from "./table_controller";
application.register("table", TableController);

import UploadController from "./upload_controller";
application.register("upload", UploadController);

import CopyController from "./copy_controller";
application.register("copy", CopyController);

import LazyLoadController from "./lazy_load_controller";
application.register("lazy-load", LazyLoadController);

import ValidationController from "./validation_controller";
application.register("validation", ValidationController);

import DisableController from "./disable_controller";
application.register("disable", DisableController);

import RiskFactorExportsController from "./risk_factor_exports_controller";
application.register("risk-factor-exports", RiskFactorExportsController);

import DashboardController from "./dashboard_controller";
application.register("dashboard", DashboardController);

import clipboardController from "./clipboard_controller";
application.register("clipboard", clipboardController);

import PopOverController from "./popover_controller";
application.register("popover", PopOverController);

import ToggleConfirmationController from "./toggle_confirmation";
application.register("toggle-confirmation", ToggleConfirmationController);

import CollectionsController from "./collections_controller";
application.register("collections", CollectionsController);

import SearchController from "./search_controller";
application.register("search", SearchController);

import ScrollableController from "./scrollable_controller";
application.register("scrollable", ScrollableController);

import RegenerateController from "./regenerate_controller";
application.register("regenerate", RegenerateController);

import ToggleHidden from "./toggle_hidden_controller";
application.register("toggle-hidden", ToggleHidden);

import NestedForm from "./nested_form_controller";
application.register("nested-form", NestedForm);
